import React, { useState, useEffect } from 'react'
import { FormGroup, Input, UncontrolledTooltip } from 'reactstrap'

const RealTimeNotificationSwitch = () => {
    const [realTimeNotification, setRealTimeNotification] = useState(
        JSON.parse(localStorage.getItem('real_time_notification')) || false
    )

    const handleToggle = () => {
        const newValue = !realTimeNotification
        setRealTimeNotification(newValue)
        localStorage.setItem('real_time_notification', JSON.stringify(newValue))
    }

    useEffect(() => {
        // Sync state with localStorage if it changes elsewhere
        const storedValue = JSON.parse(localStorage.getItem('real_time_notification'))
        if (storedValue !== null && storedValue !== realTimeNotification) {
            setRealTimeNotification(storedValue)
        }
    }, [realTimeNotification])

    return (
        <FormGroup switch className="mb-2 ml-3">
            <Input
                style={{ height: '20px', width: '36px' }}
                type="switch"
                className="custom-switch-form modals-labels"
                id="real_time_notification"
                checked={realTimeNotification}
                name="real_time_notification"
                onChange={handleToggle}
            />
            <UncontrolledTooltip
                placement="auto"
                target="real_time_notification"
                style={{ backgroundColor: '#FFFF', color: '#3D414C' }}
            >
                Real-time notifications
            </UncontrolledTooltip>
        </FormGroup>
    )
}

export default RealTimeNotificationSwitch
