import React from 'react'
import { useDispatch } from 'react-redux'
import { markRead } from 'features/dashboard/Notification.actionTypes'
import { Toast, ToastBody } from 'reactstrap'

const NewNotification = ({ modal, toggle, text, id }) => {
    const dispatch = useDispatch()

    return (
        <div
            className="new-modals shadow-sm"
            style={{
                position: 'absolute',
                top: '4rem',
                right: '10rem',
                padding: '0px',
                border: 'none',
                borderRadius: '10px',
                zIndex: '1000',
                width: '500px',
            }}
        >
            {modal && (
                <Toast style={{ width: '100%', maxWidth: '500px' }}>
                    <ToastBody
                        className="d-flex justify-content-center align-items-start"
                        style={{ gap: '20px', padding: '16px' }}
                    >
                        <img
                            src="/svgs/modal-icons/notification-icon.svg"
                            alt="notification"
                            width="48px"
                            height="48px"
                        />
                        <div className="d-flex flex-column justify-content-start align-items-start gap-1">
                            <span
                                style={{
                                    fontWeight: '500',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    letterSpacing: '0.1px',
                                    color: '#000000',
                                }}
                            >
                                New notification!
                            </span>
                            <span
                                style={{
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    letterSpacing: '0.1px',
                                    color: '#000000',
                                }}
                            >
                                {text}
                            </span>
                            <span
                                className="text-meili"
                                style={{
                                    fontWeight: '500',
                                    fontSize: '14px',
                                    lineHeight: '28px',
                                    letterSpacing: '0.1px',
                                    paragraphSpacing: '14px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    dispatch(markRead({ id })).then(({ error }) => {
                                        toggle()
                                    })
                                }}
                            >
                                Mark as seen
                            </span>
                        </div>
                        <img
                            src="/svgs/close-icon/x-dark-default.svg"
                            alt="obstacle-icon"
                            width="24px"
                            height="24px"
                            style={{ cursor: 'pointer' }}
                            onClick={toggle}
                        />
                    </ToastBody>
                </Toast>
            )}
        </div>
    )
}

export default NewNotification
