import React, { useEffect, useState } from 'react'
import {
    Button,
    ButtonGroup,
    Collapse,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { selectors } from 'features/dashboard'
import { produce } from 'immer'
import { getTeamVehicles } from 'features/dashboard/Vehicle.actionTypes'
import { getWSToken } from 'features/user/actionTypes'
import { buildWebsocketURL, useToggler } from 'utils'
import { API_ROOT, TITLE } from 'config'
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket'
import AllTasksGridView from '../Tasks/AllTasksGridView'
import TasksGridView from '../Tasks/TasksGridView'
import ScheduleTasksGridView from '../ScheduleTasksGridView'
import {
    cleanFormValues,
    getPresetTasksV2,
    getScheduleTasksV2,
    getTasksV2,
    updateTaskFromWebhookV2,
} from 'features/dashboard/Task.actionTypes'
import { getOutdoorMapPoints } from 'features/dashboard/Map.actionTypes'
import { Loading } from 'ui/common'
import MiniMap from '../MapComponents/MiniMap'
import TaskSidebar from './TaskSidebar'
import moment from 'moment'
import { useRef } from 'react'
import CancelAllTasks from '../Tasks/CancelAllTasksModal'

export const TASK_TYPES = {
    all: [],
}

const TeamTasksV2 = ({ match }) => {
    const { slug } = match.params
    const dispatch = useDispatch()
    const taskStore = useSelector(selectors.getTask)
    const devices = useSelector(selectors.getDevicess)
    const teamType = useSelector(selectors.getTeamType)
    const map = useSelector(selectors.getTeamMap)
    const areaSettings = useSelector(selectors.getTeamMap).areaSettings
    const [currentPage, setCurrentPage] = useState(1)
    const [sidebar, toggle] = useToggler()
    const [robot, setRobot] = useState('')
    const [taskHovered, setTaskHovered] = useState('')
    const [filter] = useState('all')
    const [status, setStatus] = useState('loading')
    const [icon, setIcon] = useState(areaSettings?.show_stations_names || false)
    const [currentSocketUrl, setCurrentSocketUrl] = useState(null)
    const [cancelAllModal, setCancelAllModal] = useState(false)
    const [cancelTask, setCancelTask] = useState(false)
    const [buttonText, setButtonText] = useState('View all')
    const [collapse, setCollapse] = useState({
        in_progress: false,
        queue: false,
        completed: false,
        cancelled: false,
        upcoming: false,
        all: true,
    })
    const [tasks, setTasks] = useState([
        {
            in_progress: { ...TASK_TYPES },
            queue: { ...TASK_TYPES },
            cancelled: { ...TASK_TYPES },
            all: { ...TASK_TYPES },
        },
    ])
    let setRef = window.innerWidth > 920 ? false : true
    let isMobile = useRef(setRef)

    const toggleCancelAll = () => setCancelAllModal(!cancelAllModal)

    useEffect(() => {
        const resize = () => {
            if (window.innerWidth > 920) {
                if (isMobile.current) {
                    isMobile.current = false
                }
            } else {
                if (!isMobile.current) {
                    isMobile.current = true
                }
            }
        }

        window.addEventListener('resize', resize)

        return () => window.removeEventListener('resize', resize)
    }, [])

    const { lastJsonMessage } = useWebSocket(currentSocketUrl, {
        retryOnError: true,
    })

    useEffect(() => {
        setStatus('loading')
        if (teamType === 'inside') loadInsideTeamData()
        if (teamType === 'outside') loadOutdoorTeamData()
    }, [slug]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        catgorizeTasks()
    }, [taskStore.listV2]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (currentPage > 1) dispatch(getPresetTasksV2({ slug, page: currentPage }))
    }, [currentPage]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (status === 'loaded') return
        const totalTasks = [...Object.values(taskStore.listV2), ...taskStore.schedulesV2]
        if (totalTasks.length === 0) {
            setCollapse({
                in_progress: false,
                queue: false,
                completed: false,
                cancelled: false,
                upcoming: false,
                all: false,
            })
        } else {
            setCollapse({
                in_progress: false,
                queue: false,
                completed: false,
                cancelled: false,
                upcoming: false,
                all: true,
            })
        }
    }, [taskStore.listV2, taskStore.schedulesV2]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (lastJsonMessage && lastJsonMessage.task?.team.slug === slug) {
            dispatch(updateTaskFromWebhookV2(lastJsonMessage.task))
            catgorizeTasks()
        }
    }, [lastJsonMessage]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        document.title = `${TITLE} - Missions`
        setCurrentSocketUrl(() => getSocketUrl())
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getSocketUrl = () => () => {
        return new Promise((resolve, reject) => {
            dispatch(getWSToken({ slug })).then(({ error, payload }) => {
                if (error) return reject('something went wrong on websocket')
                resolve(buildWebsocketURL(API_ROOT, payload.data.token))
            })
        })
    }

    const getRobot = (robot) => {
        setRobot(robot)
    }

    const loadOutdoorTeamData = () => {
        Promise.all([
            dispatch(getTasksV2(slug)),
            dispatch(getScheduleTasksV2({ slug })),
            dispatch(getOutdoorMapPoints({ slug })),
        ]).then(() => {
            setStatus('loaded')
        })
    }

    const loadInsideTeamData = () => {
        Promise.all([
            dispatch(getTasksV2(slug)),
            dispatch(getScheduleTasksV2({ slug })),
            dispatch(getTeamVehicles({ slug, text: 'status=true' })),
        ]).then(() => {
            setStatus('loaded')
        })
    }

    const isForCancelled = (task) =>
        task.status === 'terminated' || task.status === 'failed' || task.status === 'aborted'
    const isQueued = (task) =>
        task.status === 'pending' ||
        task.status === 'created' ||
        task.status === 'sent' ||
        task.status === 'rerun'
    const isActiveTask = (task) =>
        task.status === 'rerun' || task.status === 'created' || task.status === 'in_progress'

    const catgorizeTasks = () => {
        let filteredTask = {
            in_progress: { ...TASK_TYPES },
            queue: { ...TASK_TYPES },
            cancelled: { ...TASK_TYPES },
            completed: { ...TASK_TYPES },
            all: { ...TASK_TYPES },
        }

        Object.values(taskStore.listV2)?.forEach((task) => {
            filteredTask = produce(filteredTask, (draft) => {
                draft.all.all.push(task)

                if (isQueued(task)) {
                    draft.queue.all.push(task)
                }

                if (task.status === 'completed') {
                    draft.completed.all.push(task)
                }

                if (
                    task.status === 'in_progress' ||
                    task.status === 'paused' ||
                    task.status === 'offline' ||
                    task.status === 'await_abort'
                ) {
                    draft.in_progress.all.push(task)
                }

                if (isForCancelled(task)) {
                    draft.cancelled.all.push(task)
                }

                if (isActiveTask(task)) {
                    setCancelTask(true)
                }
            })
        })

        setTasks({
            ...filteredTask,
        })
    }

    const getMapPointsByTeamType = () => {
        if (teamType === 'inside') return map.stations
        const points = Object.values(map.outdoorPoints).reduce((acc, point) => {
            acc[point.properties.uuid] = { ...point.properties }
            return acc
        }, {})
        return points
    }

    const upcomingTask = () => {
        const currentDate = moment().format('YYYY-MM-DD HH:mm')
        const currentDay = moment().day() - 1
        return taskStore.schedulesV2
            ?.filter((tz) => {
                // Check if any of the values are null or NaN
                return (
                    (tz?.week_days_tz &&
                        tz?.date_tz + ' ' + tz?.time_tz > currentDate &&
                        !tz?.execute_every &&
                        tz?.week_days_tz.every((day) => day !== null) && // Check week_days_tz
                        tz?.date_tz !== null && // Check date_tz
                        !isNaN(Date.parse(tz?.date_tz)) && // Check if date_tz is a valid date
                        tz?.time_tz !== null && // Check time_tz
                        tz?.time_tz.trim() !== '') || // Check if time_tz is not empty
                    (tz?.execute_every &&
                        tz?.week_days_tz?.[tz?.week_days_tz?.length - 1] > currentDay)
                )
            })
            .map((task) => task)
    }

    const ToggleButton = ({ label, isActive, onClick }) => (
        <Button
            outline
            className="btn-group shadow-none"
            active={isActive}
            onClick={onClick}
            data-testid="viewallid"
        >
            {label}
        </Button>
    )

    const DropdownButton = ({ label, isActive, onClick }) => (
        <DropdownItem className="dropdownhover">
            <Button outline className="text-dark" active={isActive} onClick={onClick}>
                {label}
            </Button>
        </DropdownItem>
    )

    const handleToggle = (key, text) => {
        setButtonText(text)
        setCollapse((prevState) => ({
            ...prevState,
            in_progress: key === 'in_progress' ? !prevState.in_progress : false,
            queue: key === 'queue' ? !prevState.queue : false,
            completed: key === 'completed' ? !prevState.completed : false,
            cancelled: key === 'cancelled' ? !prevState.cancelled : false,
            upcoming: key === 'upcoming' ? !prevState.upcoming : false,
            all: key === 'all' ? !prevState.all : false,
        }))
    }

    if (status === 'loading') return <Loading />

    return (
        <>
            <div className="w-100">
                <div className="w-100" style={{ marginTop: '-45px' }}>
                    <h3 data-testid="heading">Missions</h3>
                </div>
                <div className="mt-4 pr-4 pl-0 ml-0 d-flex justify-content-between align-items-center">
                    {isMobile.current ? (
                        <UncontrolledDropdown style={{ marginBottom: '8px' }}>
                            <DropdownToggle caret className="text-dark bg-white">
                                {buttonText}
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownButton
                                    label="View all"
                                    isActive={collapse.all}
                                    onClick={() => handleToggle('all', 'View all')}
                                />
                                <DropdownButton
                                    label="In progress"
                                    isActive={collapse.in_progress}
                                    onClick={() => handleToggle('in_progress', 'In progress')}
                                />
                                <DropdownButton
                                    label="Queue"
                                    isActive={collapse.queue}
                                    onClick={() => handleToggle('queue', 'Queue')}
                                />
                                <DropdownButton
                                    label="Upcoming"
                                    isActive={collapse.upcoming}
                                    onClick={() => handleToggle('upcoming', 'Upcoming')}
                                />
                                <DropdownButton
                                    label="Completed"
                                    isActive={collapse.completed}
                                    onClick={() => handleToggle('completed', 'Completed')}
                                />
                                <DropdownButton
                                    label="Cancelled"
                                    isActive={collapse.cancelled}
                                    onClick={() => handleToggle('cancelled', 'Cancelled')}
                                />
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    ) : (
                        <ButtonGroup
                            className="my-3 d-flex align-items-center justify-content-center border"
                            style={{
                                borderRadius: '8px',
                                overflow: 'hidden',
                                height: '40px',
                                boxShadow: '0px 2px 2px 0px rgba(16, 24, 40, 0.06)',
                            }}
                        >
                            <ToggleButton
                                label="View all"
                                isActive={collapse.all}
                                onClick={() => handleToggle('all')}
                            />
                            <ToggleButton
                                label="In progress"
                                isActive={collapse.in_progress}
                                onClick={() => handleToggle('in_progress')}
                            />
                            <ToggleButton
                                label="Queue"
                                isActive={collapse.queue}
                                onClick={() => handleToggle('queue')}
                            />
                            <ToggleButton
                                label="Upcoming"
                                isActive={collapse.upcoming}
                                onClick={() => handleToggle('upcoming')}
                            />
                            <ToggleButton
                                label="Completed"
                                isActive={collapse.completed}
                                onClick={() => handleToggle('completed')}
                            />
                            <ToggleButton
                                label="Cancelled"
                                isActive={collapse.cancelled}
                                onClick={() => handleToggle('cancelled')}
                            />
                        </ButtonGroup>
                    )}

                    <div className="d-flex align-items-start">
                        <Button
                            type="button"
                            data-testid="cancelall"
                            disabled={!cancelTask}
                            className="cancel-btn-modal modals-new-btns mr-2"
                            style={{ height: '40px' }}
                            onClick={toggleCancelAll}
                        >
                            Cancel all
                        </Button>
                        <Button
                            data-testid="runmission"
                            type="button"
                            className="save-btn-modal modals-new-btns"
                            style={{ height: '40px' }}
                            onClick={() => {
                                toggle()
                                setIcon(true)
                            }}
                            disabled={sidebar || localStorage.getItem('nuke') === 'stopped'}
                        >
                            <img src="/svgs/team-tasks/play-icon-mission.svg" alt="play" />
                            Run mission
                        </Button>
                    </div>
                </div>
                <div className="p-0 m-0" style={{ scrollbarWidth: 'none' }}>
                    {collapse.all && (
                        <Collapse isOpen={collapse.all} className="d-flex" data-testid="alltasks">
                            <AllTasksGridView
                                allTasks={tasks.all}
                                upcoming={taskStore.schedulesV2}
                                filterLabel="all"
                                setTaskHovered={setTaskHovered}
                                upcomingTask={upcomingTask}
                                robot={robot}
                            />
                        </Collapse>
                    )}
                    {collapse.in_progress && (
                        <Collapse
                            isOpen={collapse.in_progress}
                            className="d-flex justify-content-between"
                            data-testid="progressgrid"
                        >
                            <TasksGridView
                                tasks={[...tasks.in_progress[filter]]}
                                setTaskHovered={setTaskHovered}
                                filterLabel="progress"
                                robot={robot}
                            />
                        </Collapse>
                    )}
                    {collapse.queue && (
                        <Collapse
                            isOpen={collapse.queue}
                            className="d-flex"
                            data-testid="queuegrid"
                        >
                            <TasksGridView
                                tasks={[...tasks.queue[filter]]}
                                setTaskHovered={setTaskHovered}
                                filterLabel=" the queue"
                                robot={robot}
                            />
                        </Collapse>
                    )}
                    {collapse.upcoming && (
                        <Collapse
                            isOpen={collapse.upcoming}
                            className="d-flex justify-content-between card_item"
                            data-testid="upcominggrid"
                        >
                            <ScheduleTasksGridView
                                setTaskHovered={setTaskHovered}
                                tasks={taskStore?.schedulesV2}
                                filterLabel="scheduled"
                                slug={slug}
                                upcomingTask={upcomingTask}
                            />
                        </Collapse>
                    )}
                    {collapse.completed && (
                        <Collapse
                            isOpen={collapse.completed}
                            className="d-flex justify-content-between"
                            data-testid="completegrid"
                        >
                            <TasksGridView
                                tasks={tasks.completed[filter]}
                                setTaskHovered={setTaskHovered}
                                filterLabel="completed"
                            />
                        </Collapse>
                    )}
                    {collapse.cancelled && (
                        <Collapse
                            isOpen={collapse.cancelled}
                            className="d-flex justify-content-between"
                        >
                            <TasksGridView
                                tasks={[...tasks.cancelled[filter]]}
                                setTaskHovered={setTaskHovered}
                                filterLabel="cancelled"
                            />
                        </Collapse>
                    )}
                </div>
                <div className="mt-0" data-testid="sidebar">
                    <MiniMap
                        getRobot={getRobot}
                        collapse={collapse}
                        taskHovered={taskHovered}
                        actions={taskStore?.taskDefinitions}
                        sidebar={sidebar}
                        icon={icon}
                        setIcon={setIcon}
                        lastJsonMessage={lastJsonMessage}
                    />
                </div>
                <TaskSidebar
                    slug={slug}
                    setIcon={setIcon}
                    sidebar={sidebar}
                    toggle={() => {
                        toggle()
                        dispatch(cleanFormValues())
                    }}
                    status={taskStore.status}
                    presets={taskStore.presetsV2.results}
                    nextPagePresets={taskStore.presetsV2.next}
                    setCurrentPage={setCurrentPage}
                    actions={taskStore?.taskDefinitions}
                    actionsPresets={taskStore?.actionsPresets}
                    stations={getMapPointsByTeamType()}
                    devices={devices}
                />
            </div>
            <CancelAllTasks
                slug={slug}
                setCancelTask={setCancelTask}
                cancelAllModal={cancelAllModal}
                toggleCancelAll={toggleCancelAll}
            />
        </>
    )
}

export default TeamTasksV2
