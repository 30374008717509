import React, { useState } from 'react'
import { Arrow } from 'react-konva'
import { calculateArrowPositions } from './MapLayerHelpers'

const RenderZonesPaths = ({
    path,
    setPath,
    setWindows,
    toggleModal,
    traversalCreate,
    draw,
    destinationPoints,
    handleTraversal,
    onlyView,
    editPoint,
    setIcon,
    setPathHovered,
}) => {
    const [pathOpacity, setPathOpacity] = useState(false)

    const clickHandle = () => {
        if (traversalCreate || (draw === true && destinationPoints.length === 1)) {
            handleTraversal(path)
            setPathHovered(null)
        } else {
            setPath(path)
            setIcon(true)
            setWindows({
                editZonesPath: true,
            })
            toggleModal()
        }
    }

    if (!path) return
    const arrowPoints = calculateArrowPositions(path.points, 10)

    return (
        <>
            <Arrow
                className="arrow"
                hitStrokeWidth={5}
                points={path.points.flat()}
                opacity={pathOpacity ? 0.6 : undefined}
                pointerAtBeginning={path.bidirectional}
                stroke={path.is_active ? '#BA4FFA' : '#E7E9EC'}
                strokeWidth={1}
                pointerWidth={1}
                pointerLength={1}
                onMouseEnter={(e) => {
                    if (!onlyView) {
                        const container = e.target.getStage().container()
                        container.style.cursor = 'pointer'
                        if (traversalCreate || (draw === true && destinationPoints.length === 1)) {
                            setPathHovered(path)
                            setPathOpacity(true)
                        }
                    }
                }}
                onMouseLeave={(e) => {
                    if (!onlyView) {
                        const container = e.target.getStage().container()
                        container.style.cursor = 'default'
                        if (traversalCreate || (draw === true && destinationPoints.length === 1)) {
                            setPathHovered(null)
                            setPathOpacity(false)
                        }
                    }
                }}
                onMouseDown={(e) => {
                    if (!onlyView) clickHandle(e)
                }}
            />

            {!path.bidirectional &&
                !editPoint &&
                arrowPoints.map(({ x, y, angle }, i) => (
                    <Arrow
                        key={i}
                        x={x}
                        y={y}
                        points={[0, 0]}
                        className="arrow"
                        hitStrokeWidth={5}
                        opacity={pathOpacity ? 0.6 : undefined}
                        rotation={angle * (180 / Math.PI)}
                        stroke={path.is_active ? '#BA4FFA' : '#E7E9EC'}
                        strokeWidth={1}
                        pointerWidth={1}
                        pointerLength={1}
                        onMouseEnter={(e) => {
                            if (!onlyView) {
                                const container = e.target.getStage().container()
                                container.style.cursor = 'pointer'
                                if (
                                    traversalCreate ||
                                    (draw === true && destinationPoints.length === 1)
                                ) {
                                    setPathHovered(path)
                                    setPathOpacity(true)
                                }
                            }
                        }}
                        onMouseLeave={(e) => {
                            if (!onlyView) {
                                const container = e.target.getStage().container()
                                container.style.cursor = 'default'
                                if (
                                    traversalCreate ||
                                    (draw === true && destinationPoints.length === 1)
                                ) {
                                    setPathHovered(null)
                                    setPathOpacity(false)
                                }
                            }
                        }}
                        onMouseDown={(e) => {
                            if (!onlyView) clickHandle(e)
                        }}
                    />
                ))}
        </>
    )
}

export default RenderZonesPaths
