import React from 'react'

const InfoHover = ({ dataHovered, mapEditors }) =>
    dataHovered && (
        <div
            className="border shadow-sm card-size overflow-hidden"
            style={{
                width: 'auto',
                height: 'auto',
                backgroundColor: 'white',
                position: 'absolute',
                top: mapEditors ? '8rem' : '1rem',
                left: '1rem',
                boxShadow: '0 0 10px rgba(0,0,0,0.5)',
                borderRadius: '5px',
                fontWeight: '500',
                padding: '12px',
            }}
        >
            <div className="d-flex flex-column justify-content-center align-items-start">
                {Object.entries(dataHovered).map(([key, value]) => (
                    <span key={key}>
                        {`${key.charAt(0).toUpperCase() + key.slice(1)}: ${value}`}
                    </span>
                ))}
            </div>
        </div>
    )

export default InfoHover
